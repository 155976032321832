import axios from '@/utils/http'
// -----------------工作台------------
// 工作台列表
export const oaWorkbench = (datavalue) => {
    return axios.request({
        url: '/oa/oaWorkbench',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 任务详情
export const taskDetail = (datavalue) => {
    return axios.request({
        url: '/oa/taskDetail',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 查询某次申请的开票数据
export const invoiceListByLogId = (datavalue) => {
    return axios.request({
        url: '/merchant/merchant-invoice/invoiceListByLogId',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}