<template>
  <div>
     <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>我的提交</div>
    <el-card style="margin-top: 10px;">
     <div>
        <el-button type="primary"  @click="change(10)" :class="{ newStyle: 10 === number }" plain>执行中</el-button>
        <el-button type="primary"  @click="change(-1)" :class="{ newStyle: -1 === number }" plain >全部</el-button>
        <el-button type="primary"  @click="change(-2)" :class="{ newStyle: -2 === number }" plain >已完成</el-button>
      </div>
      <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 30px">
      <el-table-column
        header-align="center"
        align="center"
        prop="configName"
        label="审批流名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="no"
        label="流程编号"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="stateText"
        width="130"
        label="流程状态">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="createdAt"
        label="提交时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="curApprovers"
        label="当前审批人">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="oaStateText"
        label="审批结果">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="finishTime"
        label="完成时间">
      </el-table-column>
     <el-table-column
       header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
         <el-button  type="text" size="small"  @click="seeDetails(scope.row)">查看明细</el-button>
        </template>
      </el-table-column>
      </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination>  
        </el-card> 
  </div>
</template>

<script>
import { oaWorkbench } from "@/api/workbench.js";
export default {
    data(){
        return {
            load:false,
            number:10, //筛选四个按钮
            tableData:[],
            // 分页
            pageSize: 10,
            totalPage: 0,
            totalCount: 0,
            currPage: 1,
        }
    },
    created(){
        this.change(10)
    },
    methods:{
    change(index){
      this.number = index
      this.getDataList()
    },
    //获取数据列表
    getDataList(){
        let params = {
            myType:2,
            taskType:this.number,
            current:this.currPage,
            size:this.pageSize
        }
        oaWorkbench(params).then((data) => {
            if(data.code == 200) {
                this.tableData = data.data.records
                this.totalCount = data.data.total
            }
        })
    },
    seeDetails(a){
      console.log(a);
      if (a.configId==5) {//开票申请
        this.$router.push({ 
        name: 'workbench/details',
        query:{
            id:a.taskId
        } 
       }) 
      }
      
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
    }
};
</script>

<style scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
div /deep/ .newStyle {
    background-color: #9e99b9;
    color: #fff;
}
</style>